import axios from "axios";

export const getProductNameById = async (id: string) => {
  try {
    const response = await axios.get(`/products/get_name/${id}`);

    if (response?.data?.names) {
      const names = response.data.names;
      return names.es ? names.es : names.pt ? names.pt : names.en || '';
    }
  } catch (error) {
    console.error('Error fetching product name:', error);
    return null;
  }
};

export const getOrderNumberById = async (id: string) => {
  try {
    const response = await axios.get(`/orders/get_number/${id}`);

    if (response?.data?.number) {
      const number = response.data.number;
      return number || '';
    }
  } catch (error) {
    console.error('Error fetching order number:', error);
    return null;
  }
};

export const getCustomerNameById = async (id: string) => {
  try {
    const response = await axios.get(`/customers/get_name/${id}`);

    if (response?.data?.name) {
      const name = response.data.name;
      return name || '';
    }
  } catch (error) {
    console.error('Error fetching customer name:', error);
    return null;
  }
};

export const getBusinessRuleNameById = async (id: string) => {
  try {
    const response = await axios.get(`/business_rules/get_name/${id}`);

    if (response?.data?.name) {
      const name = response.data.name;
      return name || '';
    }
  } catch (error) {
    console.error('Error fetching business rule name:', error);
    return null;
  }
};
