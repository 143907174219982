import axios from "axios";
import { defineStore } from "pinia";

export const useNotifications = defineStore("notifications-store", {
  state: () => {
    return {
      pending_notifications: false,
    };
  },

  actions: {
    async checkExistingNotifications() {
      try {
        const response = await axios.get("notifications/existing");
        if (response && response.data && response.data.status) {
          this.pending_notifications = response.data.existing_notifications;
        }
      } catch (error) {
        console.log("Error obteniendo notificaciones:", error);
      }
    },
  },
});
