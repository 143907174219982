import { createRouter, createWebHistory } from "vue-router";
import { getProductNameById, getCustomerNameById, getOrderNumberById, getBusinessRuleNameById } from "./services/api";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("./components/home/HomePage.vue"),
    redirect: "/dashboard",
    props: {
      show: false,
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("./components/dashboard/DashboardPage.vue"),
        meta: {
          title: "Inicio"
        },
      },
      {
        path: "orders",
        name: "Ordenes",
        component: () => import("./components/orders/OrdersPage.vue"),
        children: [
          {
            path: "",
            name: "Listado de Ordenes",
            component: () => import("./components/orders/OrdersGrid.vue"),
            meta: {
              title: "Ordenes"
            },
          },
          {
            path: ":id",
            name: "Detalle de Orden",
            component: () => import("./components/orders/OrderDetail.vue"),
          },
        ],
      },
      {
        path: "products",
        name: "Productos",
        component: () => import("./components/products/ProductsPage.vue"),
        children: [
          {
            path: "",
            name: "Listado de Productos",
            component: () => import("./components/products/ProductsGrid.vue"),
            meta: {
              title: "Productos"
            },
          },
          {
            path: ":id",
            name: "Detalle de Producto",
            component: () => import("./components/products/ProductDetail.vue"),
          },
        ],
      },
      {
        path: "customers",
        name: "Clientes",
        component: () => import("./components/customers/CustomersPage.vue"),
        children: [
          {
            path: "",
            name: "Listado de Clientes",
            component: () => import("./components/customers/CustomersGrid.vue"),
            meta: {
              title: "Clientes"
            },
          },
          {
            path: ":id",
            name: "Detalle de Cliente",
            component: () =>
              import("./components/customers/CustomerDetail.vue"),
          },
        ],
      },
      {
        path: "customer-categories",
        name: "Categorías de cliente",
        component: () => import("./components/customers/CustomerCategoriesGrid.vue"),
        meta: {
          title: "Categoría de clientes"
        },
      },
      {
        path: "business-rules",
        name: "Reglas de Negocio",
        component: () =>
          import("./components/business-rules/BusinessRulesPage.vue"),
        children: [
          {
            path: "",
            name: "Listado de Reglas",
            component: () =>
              import("./components/business-rules/BusinessRulesGrid.vue"),
            meta: {
              title: "Reglas"
            },
          },
          {
            path: ":id",
            name: "Detalle de Regla",
            component: () =>
              import("./components/business-rules/BusinessRuleDetail.vue"),
          },
        ],
      },
      {
        path: "coupons",
        name: "Cupones",
        component: () => import("./components/coupons/CouponsPage.vue"),
        children: [
          {
            path: "",
            name: "Listado de Cupones",
            component: () => import("./components/coupons/CouponsGrid.vue"),
            meta: {
              title: "Cupones"
            },
          },
          {
            path: ":id",
            name: "Detalle de Cupon",
            component: () =>
              import("./components/errors/UnderConstructionPage.vue"), // TODO: import("./components/coupons/CouponDetail.vue"),
          },
        ],
      },
      {
        path: "locations",
        name: "Depositos",
        component: () => import("./components/locations/LocationsPage.vue"),
        children: [
          {
            path: "",
            name: "Listado de Depositos",
            component: () => import("./components/locations/LocationsGrid.vue"),
            meta: {
              title: "Depósitos"
            },
          },
          {
            path: ":id",
            name: "Detalle de Deposito",
            component: () =>
              import("./components/errors/UnderConstructionPage.vue"), // TODO: import("./components/coupons/CouponDetail.vue"),
          },
        ],
      },
      {
        path: "custom-status",
        name: "Estados personalizados",
        component: () => import("./components/custom-statuses/CustomStatusesPage.vue"),
        children: [
          {
            path: "",
            name: "Listado",
            component: () => import("./components/custom-statuses/CustomStatusesGrid.vue"),
            meta: {
              title: "Estados Personalizados"
            },
          },
          {
            path: ":id",
            name: "Detalle",
            component: () =>
              import("./components/errors/UnderConstructionPage.vue"),
          },
        ],
      },
      {
        path: "login",
        name: "Inicio de sesión",
        component: () => import("./components/auth/LoginPage.vue"),
        meta: {
          title: "Inicio de sesión"
        },
      },
      {
        path: "register",
        name: "Registro",
        component: () => import("./components/auth/RegisterPage.vue"),
        meta: {
          title: "Registro"
        },
      },
      {
        path: "verify_account/:email",
        name: "Verificacion",
        component: () => import("./components/auth/VerifyAccountPage.vue"),
        meta: {
          title: "Verificar email"
        },
      },
      {
        path: "verify_mercadopago_account/:email",
        name: "Verificacion MercadoPago",
        component: () =>
          import("./components/auth/VerifyMercadopagoAccountPage.vue"),
        meta: {
          title: "Verificación Mercado Pago"
        },
      },
      {
        path: "password_recovery",
        name: "Recuperar contraseña",
        component: () => import("./components/auth/PasswordRecoveryPage.vue"),
        meta: {
          title: "Recuperar contraseña"
        },
      },
      {
        path: "activate_account",
        name: "Activar cuenta",
        component: () => import("./components/auth/AccountRecoveryPage.vue"),
        meta: {
          title: "Activar cuenta"
        },
      },
      {
        path: "reset_password/:email",
        name: "Restablecer contraseña",
        component: () => import("./components/auth/ResetPasswordPage.vue"),
        meta: {
          title: "Restablecer contraseña"
        },
      },
      // TODO: Users routes
      // TODO: Permissions routes
      // TODO: More routes
      {
        path: "reports",
        name: "Reportes",
        component: () =>
          import("./components/errors/UnderConstructionPage.vue"), // TODO: import("./components/reports/ReportsPage.vue")
        meta: {
          title: "Reportes"
        },
      },
      {
        path: "tools",
        name: "Tools",
        component: () => import("./components/tools/ToolsPage.vue"),
        meta: {
          title: "Tools"
        },
      },
      {
        path: "billing",
        name: "Facturación",
        component: () => import("./components/billing/BillingPage.vue"),
        meta: {
          title: "Facturación"
        },
      },
      {
        path: "configuration",
        name: "Configuración",
        component: () => import("./components/config/GeneralConfigPage.vue"),
        meta: {
          title: "Configuración"
        },
      },
      {
        path: "organization",
        name: "Organización",
        component: () => import("./components/organizations/OrganizationPage.vue"),
        meta: {
          title: "Organización"
        },
      },
      {
        path: "instructives",
        name: "Instructivos",
        component: () =>
          import("./components/instructives/InstructivesPage.vue"),
        meta: {
          title: "Instructivos"
        },
      },
      {
        path: "settings",
        name: "Mis datos",
        component: () =>
          import("./components/errors/UnderConstructionPage.vue"), // TODO: import("./components/preferences/PreferencesPage.vue")
        meta: {
          title: "Mis datos"
        },
      },
      {
        path: "not_found",
        name: "¡Ups - Página no encontrada!",
        component: () => import("./components/errors/404Page.vue"),
        meta: {
          title: "Página no encontrada"
        },
      },
      {
        path: "fatal_error",
        name: "¡Ups - Ocurrió un error!",
        component: () => import("./components/errors/500Page.vue"),
        meta: {
          title: "Error"
        },
      },
      // Instalación en tiendanube
      {
        path: "tiendanube/install/:app_name",
        name: "Instalación",
        component: () => import("./components/tiendanube/InstallPage.vue"),
        meta: {
          skipAuth: true,
          title: "Instalación",
        },
      },
    ],
  },
  // will match everything and put it under `$route.params.pathMatch`
  {
    path: "/:pathMatch(.*)*",
    redirect: "/not_found",
  },
];

const history = createWebHistory();

const router = createRouter({
  history,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.skipAuth) {
    next();
  } else {
    const loginStatus = Boolean(Number(localStorage.getItem("login_status")));

    if (to.path === "/login") {
      if (loginStatus) {
        next("/");
      } else {
        next();
      }
    } else {
      if (loginStatus) {
        next();
      } else {
        if (
          to.path === "/not_found" ||
          to.path === "/fatal_error" ||
          to.path.startsWith("/verify_account") ||
          to.path === "/password_recovery" ||
          to.path.startsWith("/password_recovery") ||
          to.path === "/reset_password" ||
          to.path.startsWith("/reset_password") ||
          to.path === "/activate_account" ||
          to.path.startsWith("/activate_account")
        ) {
          next();
        } else {
          next("/login");
        }
      }
    }
  }
});

router.afterEach(async (to) => {
  const defaultTitle = 'Lerentools';
  let title = `${defaultTitle} · ${to.meta.title}`;

  const hashRegex = /^[A-Za-z0-9]{11}$/; // Id hasheado

  if (hashRegex.test(to.params.id)) {
    if (to.path.startsWith('/products/')) {
      const productName = await getProductNameById(to.params.id);
      title = productName ? `${defaultTitle} · Productos · ${productName}` : defaultTitle;
 
    } else if (to.path.startsWith('/orders/')) {
      const orderNumber = await getOrderNumberById(to.params.id);
      title = orderNumber ? `${defaultTitle} · Órdenes · #${orderNumber}` : defaultTitle;
  
    } else if (to.path.startsWith('/customers/')) {
      const customerName = await getCustomerNameById(to.params.id);
      title = customerName ? `${defaultTitle} · Clientes · ${customerName}` : defaultTitle;
  
    } else if (to.path.startsWith('/business-rules/')) {
      const businessRuleName = await getBusinessRuleNameById(to.params.id);
      title = businessRuleName ? `${defaultTitle} · Reglas · ${businessRuleName}` : defaultTitle;
  
    }
  }

  document.title = title;
});



export default router;
