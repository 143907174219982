<template>
  <div class="card card-body mx-2 my-2 bg-base-300">
    <p>
      Estamos construyendo la experiencia del sitio para dispositivos móviles
      🚀.
    </p>
    <p>
      Lo importante es que las funcionalidades principales de la app ya están
      listas para que las uses en tu notebook o PC de escritorio, muy pronto
      esta sección estará disponible para que la uses desde tu tablet o celular.
    </p>
  </div>
</template>
