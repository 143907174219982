import { defineStore } from "pinia";

export const useCurrentInstallation = defineStore(
  "current_installation-store",
  {
    state: () => {
      return {
        loading: false,
        status: false,
        store: {},
      };
    },
    getters: {
      getStore: (state) => state.store,
    },
    actions: {
      async confirmInstallation() {
        this.status = true;
      },
      async setStore(newStore) {
        this.store = newStore;
      },
    },
  }
);
